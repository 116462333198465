import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthModalComponent } from '../modals/auth-modal/auth-modal.component';
import { PaytmService } from './paytm.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  source: string;
  constructor(
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private paytmService: PaytmService
  ) { }

  checkSource() {
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        if (params.source === 'paytm') {
          this.source = 'paytm';
        } else if (params.source === 'paytm') {
          this.source = 'phonepe'
        } else {
          this.source = 'web';
        }
      }
      );
    return this.source;
  }

  async doLogin() {
    const source = await this.checkSource();
    if (source == 'paytm') {
      this.paytmService.paytmLogin();
    } else {
      this.paytmService.getAccessToken('3243254dsfsdfn')
      /*const modal = await this.modalCtrl.create({
        component: AuthModalComponent,
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        // this.message = `Hello, ${data}!`;
      }
      */
    }
  }
}
