import { Component } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthModalComponent } from './modals/auth-modal/auth-modal.component';
import { MedualertService } from './services/medualert.service';
import { PaytmService } from './services/paytm.service';
import { SharedService } from './services/shared.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userData: any
  refreshPage$ = new BehaviorSubject<boolean>(true);
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Donate Blood', url: '/donate-blood', icon: 'water' },
    { title: 'My Booking', url: '/booking-list', icon: 'heart' },
    { title: 'Homecare', url: '/homecare', icon: 'heart' },
    { title: 'Track', url: '/track-ambulance', icon: 'archive' },
    // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public footerPages = [
    { title: 'Privacy Policy', url: '/privacy-policy', icon: 'water' },
    { title: 'Terms and Conditions', url: '/terms', icon: 'water' },
    { title: 'Refund and Cancellation', url: '/refund', icon: 'heart' },
    { title: 'FAQ', url: '/faq', icon: 'heart' },
    // { title: 'Track', url: '/track-ambulance', icon: 'archive' },
    // { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  message: string;
  showBtn: boolean = true;
  result: any
  closeBtn: boolean = false;
  navBtn: boolean = false;



  constructor(private modalCtrl: ModalController,
    public navCtrl: NavController,
    public alertController: AlertController,
    private medualertService: MedualertService,
    private paytmService: PaytmService,
    private sharedService: SharedService
  ) {
    let userData = JSON.parse(localStorage.getItem('userdetail'));
    console.log(userData);
    let brand_id = '3'


    if (userData) {
      this.showBtn = false;
      // !this.medualertService.isLoggedIn.value;
      // console.log(this.medualertService.isLoggedIn.value);

      this.userData = userData;
      this.closeBtn = true;
      this.navBtn = true;
    }
    else {

    }
  }
  async openModal() {
    this.sharedService.doLogin();
    /*
    const source = await this.sharedService.checkSource();
    if (source == 'paytm') {
      this.paytmService.paytmLogin();
    } else {
      const modal = await this.modalCtrl.create({
        component: AuthModalComponent,
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        this.message = `Hello, ${data}!`;
      }
    }*/
  }
  logOut() {
    localStorage.removeItem('userdetail');
    localStorage.removeItem('medualertAuthToken');
    localStorage.removeItem('isMedualertLoggedIn');
    localStorage.removeItem('MEDUALERT_BG_TOKEN');
    localStorage.removeItem('user_mobile');
    sessionStorage.removeItem('MEDUALERT_SERVICES');
    console.log(this.logOut)
    window.location.reload();
    this.medualertService.isLoggedIn.next(false)
    //this.refreshPage$.next

    // this.presentAlert;
    this.navCtrl.navigateBack(['home']);


  }
  async presentAlert() {
    const alert = await this.alertController.create({
      message: 'Logout Succesfully.',
      buttons: ['OK']
    });
    await alert.present();
  }
}



