import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, delay, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedualertService {
  baseUrl = 'http://medualertapi.medulance.com/api/';
  thyrocareBaseUrl = "http://api.medulance.com/api/thirdparty/thyrocare/"
  meduApiBaseUrl = "http://api.medulance.com/api/";

  isLoading: boolean;
  requestOptions: any;
  bookingList: any[];
  errMsg: any;
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private http: HttpClient,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public navCtrl: NavController
  ) {
    const authToken = localStorage.getItem('medualertAuthToken');
    this.requestOptions = {
      headers: {
        'Authorization': 'Bearer ' + authToken
      }
    };
  }


  fetchToken(bimagarage = '') {
    let authToken = localStorage.getItem('medualertAuthToken');
    this.requestOptions = {
      headers: {
        'Authorization': 'Bearer ' + authToken
      }
    };
    if (bimagarage) {
      const bgAuthToken = localStorage.getItem('MEDUALERT_BG_TOKEN') ? JSON.parse(localStorage.getItem('MEDUALERT_BG_TOKEN')).value : '';
      if (bgAuthToken && bgAuthToken !== '') {
        Object.assign(this.requestOptions.headers, { 'MEDUALERT-BG-TOKEN': bgAuthToken });
      }
    }
  }

  getData(method): Observable<any> {
    this.fetchToken();
    return this.http.get(`${this.baseUrl}${method}`, this.requestOptions).pipe(share());
  }
  postData(method, data): Observable<any> {
    this.fetchToken();
    return this.http.post(`${this.baseUrl}${method}`, data, this.requestOptions).pipe(share());
  }
  getDataTyrocare(method) {
    this.fetchToken();
    return this.http.get(`${this.thyrocareBaseUrl}${method}`, this.requestOptions);
  }
  postDataThyrocare(method, data) {
    this.fetchToken();
    return this.http.post(`${this.thyrocareBaseUrl}${method}`, data, this.requestOptions);
  }
  getDataMedu(method) {
    this.fetchToken();
    return this.http.get(`${this.meduApiBaseUrl}${method}`, this.requestOptions);
  }
  postDataMedu(method, data) {
    this.fetchToken();
    return this.http.post(`${this.meduApiBaseUrl}${method}`, data, this.requestOptions);
  }
  async present() {
    this.isLoading = true;
    return await this.loadingController.create({
      message: 'Please wait...'
      // duration: 5000,
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

  async presentToast(toastMessage: string) {
    const toast = await this.toastController.create({
      message: toastMessage,
      duration: 6000
    });
    toast.present();
  }
  onCheck() {
    const userDetail = localStorage.getItem('userdetail');
    console.log(this.isLoggedIn);

    if (userDetail) {
      return true;
    } else {
      return false;

    }
  }
}
