import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { MedualertService } from './medualert.service';

@Injectable({
  providedIn: 'root'
})
export class PaytmService {

  constructor(
    private http: HttpClient,
    private medualertService: MedualertService,
    private navCtrl: NavController
  ) { }

  paytmLogin() {
    function ready(callback) {
      if (window['JSBridge']) {
        callback && callback();
      } else {
        document.addEventListener('JSBridgeReady', callback, false);
      }
    }
    var self = this;
    ready(function () {
      window['JSBridge'].call('paytmFetchAuthCode', {
        clientId: "merchant-medulance-prod"// "e#8C%8@Q_mD9kJIZ"        //"/*your reqClient ID*/"
      }, function (result) {
        alert('authCode ' + result.data.authId);
        localStorage.setItem('ptmOid', result.data.openId);
        self.loginPaytm(result.data.authId, result.data.openId);
        // self.getAccessToken(result.data.authId);
      });
    });
  }

  loginPaytm(authId, userId) {
    alert("authId " + authId + '   userId  ' + userId)
    this.medualertService.present();
    let postData = {
      "brand_id": 3,
      "login_source": 'paytm',
      "auth_code": authId,
      "source_user_id": userId
    }
    this.medualertService.postData('user/loginBySource', postData).subscribe(result => {
      console.log(result)
      this.medualertService.dismiss();
      if (result['status_code'] === 1) {
        // this.loadOtpForm();
        // this.navCtrl.navigateRoot('/home-results');
        // this.route.navigate(['/home']);
      } else if (result['status_code'] == -4) {
        // this.presentAlertConfirm();
      }
      else {
        // this.errMsg = result['message']
      }
    }, error => {
      // this.medualertService.dismiss();
      if (error.error && error.error.status_code && error.error.status_code == -2) {
        // const userData = { mobile: this.loginForm.value.phone };
        // localStorage.setItem('userData', JSON.stringify(userData));
        // this.modalCtrl.dismiss();
        this.navCtrl.navigateRoot(['user-register']);
      } else {
        console.log(JSON.stringify(error.error))
      }
    });
  }

  getAccessToken(authCode) {
    const _this = this;
    const url = "https://accounts.paytm.com/oauth2/v2/token"
    const data = {
      "grant_type": "authorization_code",
      "code": authCode,
      "client_id": "merchant-medulance-prod",
      "scope": "basic"
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    const requestBody = formBody.join("&");
    const otherParams = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": "Basic bWVyY2hhbnQtbWVkdWxhbmNlLXByb2Q6ZSM4QyU4QFFfbUQ5a0pJWg=="
        //bWVyY2hhbnQtbWVkdWxhbmNlLXByb2Q6ZVRnUkl1ZmdGSWI0amZpcGFJMVRrT0FObzlGek1CZnQJ",
      },
      body: requestBody,
      method: "POST"
    };

    _this.apiCall(url, requestBody, otherParams).subscribe(data => {
      console.log(data);
      alert("res data " + JSON.stringify(data))
    }), err => {
      alert("Error in getacc " + err.message)
    }
    /*
        fetch(url, otherParams).then(res => {
          alert('res' + JSON.stringify(res.json()))
          res.json()
        }).then(
          res => {
            alert("res in getacc" + JSON.stringify(res));
            _this.getUserData(res['access_token']);
          })
          .catch(err => alert("error in getacc12" + err.status + err.message))
          */
  }

  apiCall(url, data, headers): Observable<any> {
    return this.http.post(url, data, headers).pipe(share());
  }

  getUserData(token) {
    const url = "https://accounts.paytm.com/v2/user?fetch_strategy=profile_info,phone_number,email"
    const otherParams = {
      headers: {
        "verification_type": "oauth_token",
        "data": token, // Auth Token you received via S2S call in previous step    
        "Authorization": "Basic bWVyY2hhbnQtbWVkdWxhbmNlLXByb2Q6ZSM4QyU4QFFfbUQ5a0pJWg=="
        //bWVyY2hhbnQtbWVkdWxhbmNlLXByb2Q6ZVRnUkl1ZmdGSWI0amZpcGFJMVRrT0FObzlGek1CZnQJ"
      },
      method: "GET"
    };
    fetch(url, otherParams)
      .then(res => res.json())
      .then(res => alert(JSON.stringify(res))).catch(err => alert("error in getuser" + JSON.stringify(err)))
  }
}
