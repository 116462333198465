// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  availableServices: [
    { name: "Ambulance", value: 1 },
    { name: "Ambulance", value: 2 },
    { name: "Doctor Visit", value: 3 },
    { name: "Physiotherapy", value: 4 },
    { name: "Eldercare", value: 5 },
    { name: "Nursing", value: 6 },
    { name: "Vaccination", value: 7 }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
