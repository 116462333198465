import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { MedualertService } from './services/medualert.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthModalModule } from './modals/auth-modal/auth-modal.module';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'





@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, FormsModule, ReactiveFormsModule, HttpClientModule,
    AuthModalModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Geolocation,
    MedualertService,
    HttpClientModule,
    NativeGeocoder,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
GoogleAuth.initialize({
  clientId: '479251008611-i3kej0af46ttfj8vo28bvmttokk2gngi.apps.googleusercontent.com',
  scopes: ['profile', 'email'],
  grantOfflineAccess: true,
});

