import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'donate-blood',
    loadChildren: () => import('./pages/donate-blood/donate-blood.module').then( m => m.DonateBloodPageModule)
  },  {
    path: 'booking-list',
    loadChildren: () => import('./pages/booking-list/booking-list.module').then( m => m.BookingListPageModule)
  },
  {
    path: 'user-register',
    loadChildren: () => import('./pages/user-register/user-register.module').then( m => m.UserRegisterPageModule)
  },
  {
    path: 'add-member',
    loadChildren: () => import('./pages/add-member/add-member.module').then( m => m.AddMemberPageModule)
  },
  {
    path: 'add-address',
    loadChildren: () => import('./pages/add-address/add-address.module').then( m => m.AddAddressPageModule)
  },
  {
    path: 'homecare',
    loadChildren: () => import('./pages/homecare/homecare.module').then( m => m.HomecarePageModule)
  },
  {
    path: 'track-ambulance',
    loadChildren: () => import('./pages/track-ambulance/track-ambulance.module').then( m => m.TrackAmbulancePageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'refund',
    loadChildren: () => import('./pages/refund/refund.module').then( m => m.RefundPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
