import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, isPlatform, MenuController, ModalController, NavController, ToastController } from '@ionic/angular';
import { MedualertService } from 'src/app/services/medualert.service';
import { IonicModule } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
})
export class AuthModalComponent implements OnInit {
  name: string;
  loginForm: FormGroup;
  otpForm: FormGroup;
  errMsg: String;
  brandLogo: String;
  isOtpSent = false;
  orgDetail: any;
  presentingElement = null;
  user = null;
  loginType = 'otp';
  lat: number;
  long: number;
  autocomplete: { input: string; };
  autocompleteItems: any[];
  autocomplete2: { input: string; };
  autocompleteItems2: any[];
  location: any;
  placeid: any;
  GoogleAutocomplete: any;
  geocoder: any;
  sourceLat: string;
  sourceLng: string;
  desLat: string;
  desLng: string;
  optionsValue = {
    now: {},
    Later: {}
  };

  OTP: any = { first: '', second: '', third: '', forth: '', fifth: '', sixth: '' };
  authform: any;
  displayLoader: boolean;
  enabledPaytm = false;

  constructor(private modalCtrl: ModalController,
    public navCtrl: NavController,
    private formBuilder: FormBuilder,
    private medualertService: MedualertService,
    private menuCtrl: MenuController,
    public alertController: AlertController,
    private toastCtrl: ToastController,
  ) {
    this.loginForm = this.formBuilder.group({
      'phone': ['', Validators.compose([
        Validators.required, Validators.minLength(10), Validators.maxLength(10)
      ])]
    });


    this.otpForm = this.formBuilder.group({
      'otp': ['', Validators.compose([
        Validators.required
      ])],
      'mobile': ['', Validators.compose([
        Validators.required, Validators.minLength(10), Validators.maxLength(10)
      ])]
    });
    // if (!isPlatform('capacitor')) {
    //   GoogleAuth.initialize();
    // }


  }
  ionViewDidEnter() {
    GoogleAuth.initialize();
  }

  ionViewWillEnter() {
    this.menuCtrl.enable(false);
  }

  ionViewDidLeave() {
    this.menuCtrl.enable(true);
  }

  ngOnInit(): void {
    this.orgDetail = JSON.parse(localStorage.getItem('organization'));

    this.displayLoader = false;
    // this.paytmLogin();

    // this.buildForm();
  }

  buildForm() {
    throw new Error('Method not implemented.');
  }

  loadOtpForm() {
    this.otpForm = this.formBuilder.group({
      'otp': ['', Validators.compose([
        Validators.required
      ])],
      'mobile': ['', Validators.compose([
        Validators.required, Validators.minLength(10), Validators.maxLength(10)
      ])]
    });
  }

  sendOTP() {
    this.medualertService.present();
    this.errMsg = '';
    let postData = {
      "brand_id": 3,
      "user_mobile": this.loginForm.value.phone,
      "login_type": this.loginType
    }
    localStorage.setItem('user_mobile', this.loginForm.value.phone)
    this.medualertService.postData('user/otp', postData).subscribe(result => {
      console.log(result)
      this.medualertService.dismiss();
      if (result['status_code'] === 1) {
        // this.loadOtpForm();
        this.isOtpSent = true;

        this.otpForm.controls.mobile.setValue(this.loginForm.controls.phone.value);
        // this.navCtrl.navigateRoot('/home-results');
        // this.route.navigate(['/home']);
      } else if (result['status_code'] == -4) {
        this.presentAlertConfirm();
      }
      else {
        this.errMsg = result['message']
      }
    }, error => {
      this.medualertService.dismiss();
      if (error.error && error.error.status_code && error.error.status_code == -2) {
        const userData = { mobile: this.loginForm.value.phone };
        localStorage.setItem('userData', JSON.stringify(userData));
        this.modalCtrl.dismiss();
        this.navCtrl.navigateRoot(['user-register']);
      } else {
        console.log(JSON.stringify(error.error))
      }
    });
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      message: 'Your email is not verified yet. Please check and verify your email to login.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('cancel');
          }
        }, {
          text: 'Resend Verification Email',
          handler: () => {
            this.resendEmailVerification();
            console.log('Resend');
          }
        }
      ]
    });

    await alert.present();
  }

  resendEmailVerification() {
    this.medualertService.present();
    this.errMsg = '';
    let postData = {
      "brand_id": this.orgDetail.id,
      "user_mobile": this.loginForm.value.phone
    }
    localStorage.setItem('user_mobile', this.loginForm.value.phone)
    this.medualertService.postData('user/resend_email_verification', postData).subscribe(result => {
      console.log(result)
      this.medualertService.dismiss();
      if (result['status_code'] === 1) {
        this.presentAlert();
      } else {
        this.errMsg = result['message']
      }
    }, error => {
      this.medualertService.dismiss();
      console.log(JSON.stringify(error.error))
    });
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      message: 'Verification link sent over your registered email. Please check and verify to login.',
      buttons: ['OK']
    });
    await alert.present();
  }

  verifyOTP() {
    const otp = this.OTP.first.toString() + this.OTP.second.toString() + this.OTP.third.toString() + this.OTP.fourth.toString();
    this.otpForm.controls.otp.setValue(otp)
    this.medualertService.present();
    this.errMsg = '';
    console.log(this.loginForm.controls.phone.value);

    let postData = {
      "brand_id": 3,
      "user_mobile": this.otpForm.value.mobile || "9716515437",
      "otp": this.otpForm.value.otp
    }
    this.medualertService.postData('user/verify', postData).subscribe(result => {
      console.log(result)
      this.medualertService.dismiss();
      if (result['status_code'] === 1) {
        this.isOtpSent = false;
        localStorage.setItem('userdetail', JSON.stringify(result['user_data']));
        localStorage.setItem('medualertAuthToken', result['token']);
        localStorage.setItem('isMedualertLoggedIn', '1');
        this.modalCtrl.dismiss();
        this.medualertService.isLoggedIn.next(true);

        window.location.reload();
        // this.navCtrl.navigateRoot('/home-results');
        this.navCtrl.navigateRoot(['home']);
        // this.medualertService.onCheck();


        window.location.reload();

      }
      else {
        this.errMsg = result['message']
      }
    }, error => {
      this.medualertService.dismiss();
      console.log(JSON.stringify(error))
    });
  }


  otpController(event, next, prev) {
    if (event.target.value.length < 1 && prev) {
      prev.setFocus()
    }
    else if (next && event.target.value.length > 0) {
      next.setFocus();
    }
    else {
      return 0;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
  async signIn() {
    this.user = await GoogleAuth.signIn();
    this.user.loginType = 'google';
    this.loginType = 'google';

    this.medualertService.present();
    this.errMsg = '';

    let postData = {
      "brand_id": 3,
      "user_mobile": this.user.email,
      "login_type": this.loginType,
      "auth_code": this.user.id
    };

    localStorage.setItem('user_mobile', this.loginForm.value.phone)
    this.medualertService.postData('user/otp', postData).subscribe(result => {
      console.log(result)
      this.medualertService.dismiss();
      if (result['status_code'] === 1) {
        // this.loadOtpForm();
        this.isOtpSent = true;
        this.otpForm.controls.mobile.setValue(this.loginForm.controls.phone.value);
        // this.navCtrl.navigateRoot('/home-results');
        // this.route.navigate(['/home']);
      } else if (result['status_code'] == -4) {
        this.presentAlertConfirm();
      }
      else {
        this.errMsg = result['message']
      }
    }, error => {
      this.medualertService.dismiss();
      if (error.error && error.error.status_code && error.error.status_code == -2) {
        localStorage.setItem('userData', JSON.stringify(this.user));
        this.modalCtrl.dismiss();
        this.navCtrl.navigateRoot(['user-register']);
      } else {
        console.log(JSON.stringify(error.error))
      }
    });
  }
  async showToast(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 2000,
      position: 'middle'
    });

    toast.present();
  }
}





